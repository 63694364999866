<template>
  <!--普通文本框-->
  <el-input v-if="fieldType==='text'" :placeholder="label" v-model="innerValue" v-bind="others" clearable>
    <span slot="suffix" v-if="others && others.suffix" style="line-height:32px;">{{others.suffix}}</span>
  </el-input>
  <!--数字输入-->
  <el-input-number v-else-if="fieldType === 'number'" v-model="innerValue" controls-position="right"></el-input-number>
  <!--Radio-->
  <div v-else-if="fieldType === 'radio'">
    <el-radio v-for="(item, index) in datas" :key="index" v-model="innerValue" :label="item.value">{{item.key}}</el-radio>
  </div>
  <!--Select-->
  <el-select v-else-if="fieldType === 'select'" filterable v-model="innerValue" :placeholder="label">
    <el-option v-for="(item, index) in datas" :label="$t('reportDetail')[item.key] || item.key" :value="item.value" :key="index"></el-option>
  </el-select>
  <!--Time-->
  <el-time-picker v-else-if="fieldType === 'time'" v-model="innerValue" :placeholder="label"></el-time-picker>
  <!--DateTime-->
  <el-date-picker :type="type" v-else-if="fieldType === 'datetime'" v-model="innerValue" :placeholder="label"></el-date-picker>
  <!--CheckBox List-->
  <el-checkbox-group v-else-if="fieldType === 'checkboxlist'" class="check-group" v-model="innerValue">
    <el-checkbox v-for="(item, index) in datas" :label="item.value" :key="index">{{item.key}}</el-checkbox>
  </el-checkbox-group>
  <!--Tip-->
  <p v-else-if="fieldType==='tip'" class="mb-3 tip--warning">{{label}}</p>

  <v-complex-chepai v-else-if="fieldType === 'complex-chepaihaoma'" v-model="innerValue"></v-complex-chepai>
</template>

<script>
import VComplexChepai from './v-complex-chepai'
export default {
  props: {
    fieldType: {
      // 类型 textfield,radio
      type: String,
      default: 'text'
    },
    valueType: {
      // 值类型 String,NumberInt,NumberFloat
      type: String,
      default: 'String'
    },
    label: {
      // 标签
      type: String,
      default: ''
    },
    name: {
      // 绑定数据名称
      type: String,
      default: ''
    },
    value: {
      // 绑定值
      default: null
    },
    others: {
      // 其他数据
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    datas: {
      // 键值对 [{key:1,value:000}]
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    innerValue: null,
    // Dialog
    modal: false,
    selected: []
  }),
  created() {
    this.innerValue = this.value
  },
  computed: {
    selectList() {
      return this.datas.map(item => {
        return { text: item.key, value: item.value }
      })
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal
    },
    innerValue(newVal) {
      // if (this.valueType === 'String') {
      //   newVal += ''
      // } else if (this.valueType === "NumberInt") {
      //   newVal = parseInt(newVal)
      // } else if (this.valueType === 'NumberFloat') {
      //   newVal = parseFloat(newVal)
      // }

      this.$emit('input', newVal)
    }
  },
  components: {
    VComplexChepai
  },
}
</script>

<style lang="scss" scoped>
.tip--warning {
  color: #FF5722;
  margin-bottom: 10px;
}
.check-group {
  & .el-checkbox {
    margin-right: 30px;
  }
  & .el-checkbox + .el-checkbox {
    margin-left: 0;
  }
}
</style>
